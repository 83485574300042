import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"

import { device, useContentfulSection, useMeta } from "utils"

import Videos from "src/components/sections/Videos/Videos"
import { Helmet } from "react-helmet"

// import { AboutTeam, AboutTestimonial } from "src/components/sections/about"

const VideoPages = ({ data: { videos, video } }) => {
  const sections = useContentfulSection(video.sections)

  const meta = useMeta(video)

  return (
    <Layout>
      <Seo {...meta} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Nav />
      <Wrap>
        <Videos {...sections.videos_hero} videos={videos} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  background: #f9f9f9;

  padding-top: 25vh;

  @media ${device.laptop} {
    padding-top: 15vh;
  }
`

export const query = graphql`
  {
    video: contentfulPage(pageId: { eq: "videos" }) {
      ...Page
    }
    videos: allContentfulVideo(sort: { fields: date, order: ASC }) {
      edges {
        node {
          id
          slug
          title
          description {
            childrenMarkdownRemark {
              rawMarkdownBody
            }
          }
          youtubeUrl
        }
      }
    }
  }
`

export default VideoPages
