import React, { useRef } from "react"
import { Link } from "gatsby"

import { device } from "src/utils"
import styled from "styled-components"
// import { useIntersectionObserver } from "react-intersection-observer-hook"

export default function VideoCard({ title, description, youtubeUrl, slug }) {
  // const [ref, { entry }] = useIntersectionObserver({ threshold: 1 })

  // const videoRef = useRef(null)
  // const ogVideoUrl = useRef(youtubeUrl)

  // const isVisible = entry?.isIntersecting

  // useEffect(() => {
  //   if (isVisible) {
  //     // ?&autoplay=1&playsinline=1&rel=0
  //     videoRef.current.src += "?&autoplay=1&playsinline=1&rel=0"
  //   } else {
  //     // console.log("no visibile:", title)
  //     videoRef.current.src = ogVideoUrl.current
  //   }
  // }, [isVisible])

  return (
    <StyledVideoCard>
      <div className="iframeContainer">
        <iframe
          // ref={videoRef}
          width="100%"
          height="100%"
          src={youtubeUrl}
          title={title}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div className="bottom">
        <Link to={`/videos/${slug}`}>
          <h3 className="title">{title}</h3>
        </Link>
        <p className="description">
          {description.childrenMarkdownRemark[0].rawMarkdownBody}
        </p>
      </div>
    </StyledVideoCard>
  )
}

const StyledVideoCard = styled.div`
  .iframeContainer {
    width: 100%;
    /* height: 500px; */
    /* height: 60svh; */
    padding-bottom: 56.56%;
    position: relative;

    iframe {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .bottom {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media ${device.laptop} {
      padding: 24px 32px;
    }

    .title {
      font-family: "PP Mori";
      font-weight: 600;
    }
    .description {
      font-size: 1rem;
      line-height: 1.4;
      margin-top: 4px;
      /* max-width: 500px; */
    }
  }
`
